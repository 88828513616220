import React from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../utils";
import { Editedtext } from "../CommonComps/TextTemplate";
import { DataStatus } from "../CommonComps/DataStatus";
import CommonPagination from "../CommonComps/CommonPagination";
import AmountInfo from "../CommonComps/AmountInfo"; // Assuming you have this component

// Row component to display each row's data
const Row = ({ row, header, index }) => {
  return (
    <>
      <StyledTableRow sx={{ backgroundColor: "#9dceff !important" }}>
        {header.map((item, headerIndex) =>
          item.isShow && (
            <StyledTableCell
              key={headerIndex}
              size="small"
              sx={{
                textAlign:
                  item.name === "S.NO"
                    ? "center"
                    : item.name === "Vendor Name"
                    ? "Start"
                    : "end",
                paddingLeft: 0,
                width:
                  item.name === "S.NO"
                    ? 94
                    : item.name === "Vendor Name"
                    ? 210
                    : 200
              }}
            >
              {/* For S.NO, show index + 1 */}
              {item.name === "S.NO" ? (
                index + 1
              ) : !isNaN(row[item.alias]) ? (
                <AmountInfo amount={parseFloat(row[item.alias])} />
              ) : (
                <Editedtext text={row[item.alias]} textColor={"inherit"} />
              )}
            </StyledTableCell>
          )
        )}
      </StyledTableRow>
    </>
  );
};

// PayableTable component to display the table
const PayableTable = ({
  headerConfig,
  data,
  pageNo,
  setPageNo,
  totalPages,
  setPageSize,
  pageSize
}) => {
  const onPageChange = (event, value) => setPageNo(value);

  console.log(headerConfig)

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
          height: data?.length > 1 ? "38vh" : "auto",
          overflowX: "auto"
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <StyledTableRow>
              {headerConfig.map(
                ({ name, id, isShow }, columnIndex) =>
                  isShow && (
                    <StyledTableCell
                      key={columnIndex}
                      sx={{
                        minWidth:
                          name === "S.NO"
                            ? 94
                            : name === "Vendor Name"
                            ? 210
                            : 200,
                        textAlign:
                          name === "S.NO"
                            ? "center"
                            : name === "Vendor Name"
                            ? "Start"
                            : "end",
                        padding: name === "S.NO" ? "0px 20px" : "auto"
                      }}
                    >
                      {name}
                    </StyledTableCell>
                  )
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  header={headerConfig}
                  index={index} // Pass the index for S.NO
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data && data.length < 1 && <DataStatus />}
      {data && data.length > 0 && (
        <CommonPagination
          pageSize={pageSize}
          totalPages={totalPages}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          onPageChange={onPageChange}
          sx={{ minWidth: 220, margin: "0 20px" }}
          label="Report's"
        />
      )}
    </>
  );
};

export default PayableTable;
