import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import {
  Grid,
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";
import { themecolor } from "../Navigation";
import CloseIcon from "@mui/icons-material/Close";
import FilterTemplate from "./FilterTemplate";
import WorkIcon from "@mui/icons-material/Work";
import { useNavigate } from "react-router-dom";

const SideBar = ({
  toggleDrawer,
  anchor,
  handleSubmitFilterValue,
  filteredValues,
  notification,
  notificationData,
  setState,
  state,
  handleResetFilter,
}) => {
  const navigate = useNavigate();
  const [reset, setReset] = useState(false);

  const getFilteredValue =
    filteredValues &&
    filteredValues?.map((ele) => ({
      label: ele?.label,
      style: ele?.style,
      type: ele?.type,
      typeId: ele?.typeId,
      value: ele?.value,
    }));
  const currentLocation = window.location.pathname;
  function setDataHandle() {
    const filterData = {
      pathname: currentLocation,
      value: getFilteredValue,
    };

    const filterAction =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));

    if (filterAction) {
      // Check if the pathname already exists in the localStorage
      const existingIndex = filterAction.findIndex(
        (item) => item.pathname === filterData.pathname
      );

      if (existingIndex >= 0) {
        // Update existing pathname filter
        filterAction[existingIndex] = filterData;
      } else {  
        // Add new pathname filter
        filterAction.push(filterData);
      }

      localStorage.setItem("filter", JSON.stringify(filterAction));
    } else {
      // Initialize with the current filterData
      const itemArr = [filterData];
      localStorage.setItem("filter", JSON.stringify(itemArr));
    }
  }

  useEffect(() => {
    setDataHandle();
  }, [filteredValues]);

  return (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 460 }}
      role="presentation"
    >
      <Grid
        container
        xs={12}
        spacing={2}
        style={{ padding: "0px 15px", margin: "auto" }}
      >
        <Grid item xs={4} align="left">
          {!notification ? (
            <Typography
              variant={"h5"}
              style={{ fontWeight: "600", marginTop: 10 }}
            >
              Filters
            </Typography>
          ) : (
            <Typography
              variant={"h5"}
              style={{ fontWeight: "600", marginTop: 10 }}
            >
              Notifications
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} align="right" style={{ marginTop: 15 }}>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer(anchor, false)}
          />
        </Grid>
      </Grid>

      <Divider className="divider" />
      {!notification ? (
        <Grid container>
          {filteredValues &&
            filteredValues.length > 0 &&
            filteredValues.map((filter, index) => (
              <Grid item xs={6} key={index}>
                <FilterTemplate filteredValues={[filter]} />
                {index <= filteredValues.length - 1 && (
                  <Divider className="divider" />
                )}
              </Grid>
            ))}
        </Grid>
      ) : (
        <Grid container>
          <Grid item md={12}>
            <List sx={{ width: "100%" }}>
              {notificationData.map((item) => (
                <ListItem
                  key={item.notificationId}
                  divider
                  button
                  alignItems="flex-start"
                >
                  <ListItemAvatar>
                    <Avatar alt="Notification" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.content}
                    secondary={
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {item.type.name} - {item.createdOn}
                        </Typography>
                        <span>{item.type.name}</span>
                        {item.typeId === 1 && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setState({ ...state, [anchor]: false });
                              navigate("/pendingApprovals");
                            }}
                            style={{
                              marginTop: "1rem",
                              width: "52%",
                              // backgroundColor: "#4CAF50",
                              // fontWeight: "bold",
                            }}
                          >
                            Pending Approvals
                          </Button>
                        )}
                      </div>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )}

      {!notification ? (
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              style={{ background: `${themecolor}` }}
              color="primary"
              variant="contained"
              onClick={() => {
                handleSubmitFilterValue();
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              style={{ marginRight: 5 }}
              onClick={toggleDrawer(anchor, false)}
            >
              cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ background: `rgb(55 85 110)` }}
              color="primary"
              variant="contained"
              onClick={() => {
                handleResetFilter();
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </Box>
  );
};

export default SideBar;
