import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Pagination,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  IconButton,
  Autocomplete,
  TextField,
  Collapse,
  Divider,
  Button,
  TableRow,
  TableCell,
  InputAdornment
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FcInfo } from "react-icons/fc";
import { headers } from "./util";
import moment from "moment";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import InfoIcon from "@mui/icons-material/Info";
import {
  DataStatus,
  updateNestedItem,
  zeroBalanceReportFilter
} from "../utils/index";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { pageSizes } from "../Account/util";
import { StyledTableCell, StyledTableRow } from "../utils/index";
// import Color from "color";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaDollarSign, FaFontAwesome, FaRupeeSign } from "react-icons/fa";
import { NumberFormatter } from "../CommonComps/NumberFormatter";
import AmountInfo from "../CommonComps/AmountInfo";
import { getEncryptPayload } from "../Network_Utility";
import { reportChildListUrl, trialBalanceUrl } from "../config";
import { userService } from "../userService";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import { TbWorldSearch } from "react-icons/tb";

import ColorInfo from "../CommonComps/ColorInfo";
import MySwitch from "../CommonComps/Switch";
import { Editedtext } from "../CommonComps/TextTemplate";

// function getColorForLevel(level) {
//   // Define a color scheme based on levels
//   const colorSchemes = [
//     "#DDEEFF", // Dark Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//   ];

//   // Use modulo to cycle through colors
//   return colorSchemes[level % colorSchemes.length];
// }

function Row({
  childRow,
  index,
  handleRevert,
  site,
  level,
  item,
  color,
  setLoading,
  successAlert,
  errorAlert,
  getApprovedBal,
  tableData,
  setData,
  totalProfit,
  navigateToLedger,

  fromDate,
  toDate,
  monthId,
  showOpeningBalance,
  isBalanceWiseData
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { childList } = childRow;

  const [rowData, setRowData] = useState(childRow || {});
  const [isAccount, setIsAccount] = useState(false);
  const [accountSerchValue, setAccountSearchValue] = React.useState("");
  const [pageNum, setPageNum] = React.useState(0);
  const [balanceStatus, setBalanceStatus] = useState(true);
  const [infiniteLoaderKey, setInfiniteLoaderKey] = useState(0);

  const isLeafNode = !childList || childList.length == 0;

  const handleClickIcon = (row) => {
    if (!isCollapsed) {
      if (!row.childList || (row.childList && row.childList.length == 0)) {
        makeAPICallOnLeafNode(row);
        // const permissionExist = checkPermissionExist(endPoints, trialBalanceUrl)
        // if (permissionExist)

        // else errorAlert(`Unautharized to access accounts trial balance of ${row.name} group.`)
      } else setIsCollapsed(!isCollapsed);
      // Set isCollapsed to true before making the API call
    } else setIsCollapsed(!isCollapsed); // Toggle isCollapsed state
  };

  const makeAPICallOnLeafNode = async (row) => {
    const payload = {
      reportId: 5,
      groupId: row?.id,
      siteId: localStorage.getItem("siteId"),
      monthId: monthId,
      fromDate: fromDate,
      toDate: toDate
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    const apiUrl = reportChildListUrl;

    try {
      setLoading(true);
      const response = await userService.postData(
        apiUrl,
        encryptedPayload,
        null,
        true
      );

      if (response && response.data && response.data.status == 200) {
        setLoading(false);
        if (
          response?.data?.data &&
          response?.data?.data.length > 0 &&
          response?.data?.data[0]?.childList?.length > 0
        ) {
          const updatedRow = {
            ...row,
            childList: response?.data?.data[0]?.childList
          };
          const balanceFilterRow = {
            ...row,
            childList: zeroBalanceReportFilter(
              response?.data?.data[0]?.childList
            )
          };
          if (isBalanceWiseData) {
            setRowData(balanceFilterRow);
            if (balanceFilterRow?.childList?.length > 0) {
              setIsCollapsed(true);
            } else {
              setIsCollapsed(false);
              errorAlert("There are no accounts that have a balance!");
            }
          } else {
            setRowData(updatedRow);
            if (updatedRow?.childList?.length > 0) {
              setIsCollapsed(true);
            } else {
              setIsCollapsed(false);
            }
          }

          // const updatedData = updateNestedItem(tableData, updatedRow)
          // setData(updatedData);

          // setIsCollapsed(true);
        } else getAccountsList(row, 1, false, balanceStatus);
      } else {
        setLoading(false);
        setIsCollapsed(false);
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      setIsCollapsed(false);
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      // setLoading(false);
    }
  };

  const getAccountsList = async (
    row,
    pageN,
    isSearch,
    balanceStatus,
    fetch
  ) => {
    const payload = {
      monthId: monthId,
      fromDate: fromDate,
      toDate: toDate,
      siteId: parseInt(localStorage.getItem("siteId")),
      accountGroupIds: [row?.id],
      financialYearId: 5,
      pageNo: pageN || 1,
      pageSize: 20,
      searchKey: accountSerchValue
        ? accountSerchValue?.trim().replace(/\s+/g, " ")
        : "",
      isHavingTransaction: balanceStatus
    };

    const apiUrl = trialBalanceUrl;
    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      pageN === 1 && setLoading(true);
      const response = await userService.postData(apiUrl, encryptedPayload);

      if (response && response.data && response.data.status === 200) {
        pageN === 1 && setLoading(false);
        const { accountInfoList } = response.data.data?.data;

        if (accountInfoList && accountInfoList.length > 0) {
          setIsCollapsed(true);
          setIsAccount(true);
          const newList = accountInfoList.map((item) => ({
            ...item,
            accountId: item?.accountId,
            name: item.accountName,

            isAccount: true,
            childList: [] // Make sure to set childList as an empty array
          }));

          const updatedRow =
            rowData?.childList && !isSearch && !fetch
              ? { ...row, childList: [...rowData?.childList, ...newList] }
              : { ...row, childList: newList };
          // const updatedRow = { ...row, childList: newList };
          setRowData(updatedRow);

          // Update the main data array with the updated row
          // const updatedData = updateNestedItem(tableData, updatedRow)
          // setData(updatedData);

          // Toggle isCollapsed state
        } else {
          if (pageN === 1) {
            const updatedRow = { ...row, childList: [] };
            setRowData(updatedRow);
            setLoading(false);
            successAlert(`No Account available for ${row.name}`);
          }
          // If there are no child items, just toggle isCollapsed state
          // setIsCollapsed(!isCollapsed);
          if (rowData.childList) {
            if (rowData.childList.length === 0) {
              successAlert(`No Account available for ${row.name}`);
            }
          } else {
            successAlert(`No Account available for ${row.name}`);
          }
        }
      } else {
        pageN === 1 && setLoading(false);
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      pageN === 1 && setLoading(false);
      if (rowData.childList) {
        if (rowData.childList.length === 0) {
          successAlert(`No Account available for ${row.name}`);
        }
      } else {
        successAlert(`No Account available for ${row.name}`);
      }
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      // setLoading(false);
    }
  };

  // const color = getColorForLevel(level);
  const paddingLeft = `10px!important`;

  const resetInfiniteLoader = () => {
    setInfiniteLoaderKey((prevKey) => prevKey + 1); // Increment the key to force re-render
    setPageNum(1);
  };

  const isAccountLoaded = ({ index }) => {
    if (!isAccount) return;
    const loadedItem = !!rowData.childList[index];
    return loadedItem;
  };

  const loadMoreRows = ({ startIndex, stopIndex }) => {
    let quotient = Math.ceil(stopIndex / 20);
    const pageSize = quotient + 1;
    console.log(pageSize);
    if (pageNum === pageSize) {
      return;
    }
    setPageNum(pageSize);
    getAccountsList(childRow, pageSize, false, balanceStatus);
  };

  const searchAccountHandle = () => {
    setTimeout(() => {
      resetInfiniteLoader();
    }, 500);
    getAccountsList(childRow, 1, true, balanceStatus);
  };

  useEffect(() => {
    childRow && setRowData(childRow);
  }, [childRow]);

  console.log(rowData, "vahdbh");
  return (
    <React.Fragment>
      <StyledTableRow
        className="plRow"
        sx={{
          cursor: "pointer",
          backgroundColor: `${color}!important`,
          marginTop: "1rem!important"
        }}
        // onClick={() =>
        //   row?.childList?.length == 0

        //     ? toggleAccountTransactions(row)
        //     : toggleCollapse()
        // }
      >
        {/* <StyledTableCell
          sx={{ padding: "5px 0px!important", paddingLeft: paddingLeft }}
        >
          {showExpandIcon ? (
            <IconButton
              onClick={toggleCollapse}
              sx={{ padding: "0px!important" }}
            >
              {isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          ) : (
            <IconButton
              onClick={toggleCollapse}
              sx={{ padding: "0px!important" }}
            >
              <ExpandMoreIcon sx={{ visibility: "hidden" }} />
            </IconButton>
          )}
          {row?.childList?.length == 0 && !row?.isAccount && (
            <IconButton
              onClick={() => toggleAccountTransactions(row)}
              sx={{ padding: "0px!important" }}
            >
              {isAccountTransactionsOpen ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          )}
          <span style={{ color: row?.isAccount && "blue" }} >{row?.name}</span>
        </StyledTableCell> */}

        {rowData && !rowData?.isAccount ? (
          <StyledTableCell
            style={{
              padding: "5px 0px!important",
              paddingLeft: paddingLeft
            }}
          >
            <Typography variant="body2" className="table_value">
              <IconButton
                aria-label="expand rowData"
                size="small"
                onClick={() => handleClickIcon(rowData)}
              >
                {isCollapsed ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
              <span style={{ color: rowData?.isAccount && "blue" }}>
                <Editedtext num="50" text={rowData?.name} />
                {/* {rowData?.name} */}
              </span>
            </Typography>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            style={{
              padding: "5px 0px!important",
              paddingLeft: paddingLeft
            }}
          >
            <Typography variant="body2" className="table_value">
              <IconButton disabled>
                <KeyboardArrowUpIcon style={{ visibility: "hidden" }} />
              </IconButton>
              <span
                style={{ color: rowData?.isAccount && "blue" }}
                onClick={() => navigateToLedger(rowData)}
              >
                <Editedtext num="50" text={rowData?.name} />
                {/* {rowData?.name} */}
              </span>
            </Typography>
          </StyledTableCell>
        )}

        <StyledTableCell sx={{ padding: "5px 0px!important" }}>
          {!getApprovedBal ? (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginRight: "10px"
                // paddingRight:
                //   row?.amount == undefined || row?.amount == 0 ? "22px" : "0px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color:
                    rowData?.accountGroupCurrentBalance -
                      rowData?.openingBalance ==
                    0
                      ? "black"
                      : rowData?.accountGroupCurrentBalance -
                          rowData?.openingBalance >
                        0
                      ? "red"
                      : "green"
                }}
              >
                {rowData?.openingBalance ||
                rowData?.accountGroupCurrentBalance ? (
                  <>
                    {showOpeningBalance && (
                      <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                        <AmountInfo amount={rowData?.openingBalance} />
                        {" | "}{" "}
                      </span>
                    )}
                    <AmountInfo
                      amount={
                        rowData?.accountGroupCurrentBalance -
                        rowData?.openingBalance
                      }
                    />
                  </>
                ) : (
                  "-"
                )}
              </span>
            </span>
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center"

                // paddingRight:
                //   rowData?.amount == undefined || rowData?.amount == 0 ? "22px" : "0px",
              }}
            >
              {" "}
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginRight: "10px",
                  color: rowData?.approvedAccountGroupCurrentBalance
                    ? rowData?.approvedAccountGroupCurrentBalance -
                        rowData?.approvedOpeningBalance ==
                      0
                      ? "black"
                      : rowData?.approvedAccountGroupCurrentBalance -
                          rowData?.approvedOpeningBalance >
                        0
                      ? "red"
                      : "green"
                    : rowData?.approvedClosingBalance
                    ? rowData?.approvedClosingBalance -
                        rowData?.approvedOpeningBalance ==
                      0
                      ? "black"
                      : rowData?.approvedClosingBalance -
                          rowData?.approvedOpeningBalance >
                        0
                      ? "red"
                      : "green"
                    : "green"
                  // color:
                  //   rowData?.approvedAccountGroupCurrentBalance -
                  //     rowData?.approvedOpeningBalance ==
                  //   0
                  //     ? "black"
                  //     : rowData?.approvedAccountGroupCurrentBalance -
                  //         rowData?.approvedOpeningBalance >
                  //       0
                  //     ? "red"
                  //     : "green",
                }}
              >
                {/* {rowData?.approvedOpeningBalance ||
                rowData?.approvedAccountGroupCurrentBalance ? (
                  <AmountInfo
                    amount={
                      rowData?.approvedAccountGroupCurrentBalance -
                      rowData?.approvedOpeningBalance
                    }
                  />
                ) : (
                  "-"
                )} */}

                {rowData?.approvedAccountGroupCurrentBalance ? (
                  rowData?.approvedOpeningBalance ||
                  rowData?.approvedAccountGroupCurrentBalance ? (
                    <>
                      {showOpeningBalance && (
                        <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                          <AmountInfo amount={rowData?.openingBalance || 0} />
                          {" | "}{" "}
                        </span>
                      )}
                      <AmountInfo
                        amount={
                          rowData?.approvedAccountGroupCurrentBalance -
                          rowData?.approvedOpeningBalance
                        }
                      />
                    </>
                  ) : (
                    "-"
                  )
                ) : rowData?.approvedClosingBalance ? (
                  rowData?.approvedOpeningBalance ||
                  rowData?.approvedClosingBalance ? (
                    <>
                      {showOpeningBalance && (
                        <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                          <AmountInfo amount={rowData?.openingBalance || 0} />
                          {" | "}{" "}
                        </span>
                      )}
                      <AmountInfo
                        amount={
                          rowData?.approvedClosingBalance -
                          rowData?.approvedOpeningBalance
                        }
                      />
                    </>
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </span>
            </span>
          )}
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell
          colSpan={3}
          sx={{ padding: "5px 0px!important", borderBottom: "none" }}
        >
          <Collapse in={isCollapsed}>
            <div>
              {isAccount && rowData && (
                <Grid container alignItems={"center"} spacing={3}>
                  <Grid item sx={{ paddingLeft: "9px" }}>
                    <TextField
                      value={accountSerchValue}
                      onChange={(e) => setAccountSearchValue(e.target.value)}
                      margin="dense"
                      size="small"
                      style={{
                        maxWidth: 250
                      }}
                      label="Search"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={searchAccountHandle}
                            >
                              <TbWorldSearch />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <MySwitch
                      checked={balanceStatus}
                      onChange={(e) => {
                        setBalanceStatus(e.target.checked);
                        setTimeout(() => {
                          resetInfiniteLoader();
                        }, 500);
                        getAccountsList(
                          childRow,
                          1,
                          Boolean(accountSerchValue?.trim()),
                          e.target.checked,
                          true
                        );
                      }}
                      label={!balanceStatus ? "Balance On" : "Balance Off"}
                    />
                  </Grid>
                </Grid>
              )}
              <TableContainer>
                <Table>
                  <TableBody>
                    {isAccount && rowData ? (
                      <AutoSizer style={{ height: "300px" }}>
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            rowData.childList &&
                            rowData.childList.length > 0 && (
                              <InfiniteLoader
                                key={infiniteLoaderKey}
                                isRowLoaded={isAccountLoaded}
                                loadMoreRows={loadMoreRows}
                                rowCount={
                                  Math.ceil(
                                    rowData.childList?.length / itemsPerRow
                                  ) + 1
                                }
                              >
                                {({ onRowsRendered, registerChild }) => (
                                  <List
                                    onRowsRendered={onRowsRendered}
                                    ref={registerChild}
                                    width={width}
                                    height={300}
                                    rowHeight={50}
                                    rowCount={Math.ceil(
                                      rowData.childList?.length / itemsPerRow
                                    )}
                                    overscanRowCount={3}
                                    rowRenderer={({ index, key, style }) => {
                                      const startIndex = index * itemsPerRow;
                                      const endIndex = Math.min(
                                        startIndex + itemsPerRow,
                                        rowData.childList?.length
                                      );
                                      const items = rowData.childList
                                        ?.slice(startIndex, endIndex)
                                        .map((val, ind) => (
                                          <>
                                            <StyledTableCell
                                              style={{
                                                paddingLeft: paddingLeft,
                                                minWidth: "300px",
                                                width: "800px"
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                className="table_value"
                                              >
                                                <IconButton disabled>
                                                  <KeyboardArrowUpIcon
                                                    style={{
                                                      visibility: "hidden"
                                                    }}
                                                  />
                                                </IconButton>
                                                <span
                                                  style={{
                                                    color:
                                                      val?.isAccount && "blue"
                                                  }}
                                                  onClick={() =>
                                                    navigateToLedger(val)
                                                  }
                                                >
                                                  <Editedtext
                                                    num="50"
                                                    text={val?.name}
                                                  />
                                                  {/* {val?.name} */}
                                                </span>
                                              </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell
                                              sx={{
                                                padding: "5px 0px !important"
                                              }}
                                            >
                                              {!getApprovedBal ? (
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    alignItems: "center",
                                                    marginRight: "10px"
                                                    // paddingRight:
                                                    //   row?.amount == undefined || row?.amount == 0 ? "22px" : "0px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      color:
                                                        val?.accountGroupCurrentBalance -
                                                          val?.openingBalance ==
                                                        0
                                                          ? "black"
                                                          : val?.accountGroupCurrentBalance -
                                                              val?.openingBalance >
                                                            0
                                                          ? "red"
                                                          : "green"
                                                    }}
                                                  >
                                                    {val?.openingBalance ||
                                                    val?.accountGroupCurrentBalance ? (
                                                      <>
                                                        {showOpeningBalance && (
                                                          <span
                                                            style={{
                                                              color: "#2d269f",
                                                              padding: "0px 2px"
                                                            }}
                                                          >
                                                            <AmountInfo
                                                              amount={
                                                                val?.openingBalance
                                                              }
                                                            />
                                                            {" | "}{" "}
                                                          </span>
                                                        )}
                                                        <AmountInfo
                                                          amount={
                                                            val?.accountGroupCurrentBalance -
                                                            val?.openingBalance
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      "-"
                                                    )}
                                                  </span>
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    alignItems: "center"

                                                    // paddingRight:
                                                    //   val?.amount == undefined || val?.amount == 0 ? "22px" : "0px",
                                                  }}
                                                >
                                                  {" "}
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      marginRight: "10px",
                                                      color:
                                                        val?.approvedAccountGroupCurrentBalance
                                                          ? val?.approvedAccountGroupCurrentBalance -
                                                              val?.approvedOpeningBalance ==
                                                            0
                                                            ? "black"
                                                            : val?.approvedAccountGroupCurrentBalance -
                                                                val?.approvedOpeningBalance >
                                                              0
                                                            ? "red"
                                                            : "green"
                                                          : val?.approvedClosingBalance
                                                          ? val?.approvedClosingBalance -
                                                              val?.approvedOpeningBalance ==
                                                            0
                                                            ? "black"
                                                            : val?.approvedClosingBalance -
                                                                val?.approvedOpeningBalance >
                                                              0
                                                            ? "red"
                                                            : "green"
                                                          : "green"
                                                      // color:
                                                      //   val?.approvedAccountGroupCurrentBalance -
                                                      //     val?.approvedOpeningBalance ==
                                                      //   0
                                                      //     ? "black"
                                                      //     : val?.approvedAccountGroupCurrentBalance -
                                                      //         val?.approvedOpeningBalance >
                                                      //       0
                                                      //     ? "red"
                                                      //     : "green",
                                                    }}
                                                  >
                                                    {/* {val?.approvedOpeningBalance ||
                                            val?.approvedAccountGroupCurrentBalance ? (
                                              <AmountInfo
                                                amount={
                                                  val?.approvedAccountGroupCurrentBalance -
                                                  val?.approvedOpeningBalance
                                                }
                                              />
                                            ) : (
                                              "-"
                                            )} */}

                                                    {val?.approvedAccountGroupCurrentBalance ? (
                                                      val?.approvedOpeningBalance ||
                                                      val?.approvedAccountGroupCurrentBalance ? (
                                                        <>
                                                          {showOpeningBalance && (
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#2d269f",
                                                                padding:
                                                                  "0px 2px"
                                                              }}
                                                            >
                                                              <AmountInfo
                                                                amount={
                                                                  val?.openingBalance ||
                                                                  0
                                                                }
                                                              />
                                                              {" | "}{" "}
                                                            </span>
                                                          )}
                                                          <AmountInfo
                                                            amount={
                                                              val?.approvedAccountGroupCurrentBalance -
                                                              val?.approvedOpeningBalance
                                                            }
                                                          />
                                                        </>
                                                      ) : (
                                                        "-"
                                                      )
                                                    ) : val?.approvedClosingBalance ? (
                                                      val?.approvedOpeningBalance ||
                                                      val?.approvedClosingBalance ? (
                                                        <>
                                                          {showOpeningBalance && (
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#2d269f",
                                                                padding:
                                                                  "0px 2px"
                                                              }}
                                                            >
                                                              <AmountInfo
                                                                amount={
                                                                  val?.openingBalance ||
                                                                  0
                                                                }
                                                              />
                                                              {" | "}{" "}
                                                            </span>
                                                          )}
                                                          <AmountInfo
                                                            amount={
                                                              val?.approvedClosingBalance -
                                                              val?.approvedOpeningBalance
                                                            }
                                                          />
                                                        </>
                                                      ) : (
                                                        "-"
                                                      )
                                                    ) : (
                                                      "-"
                                                    )}
                                                  </span>
                                                </span>
                                              )}
                                            </StyledTableCell>
                                          </>
                                        ));

                                      return (
                                        <StyledTableRow key={key} style={style}>
                                          {items}
                                        </StyledTableRow>
                                      );
                                    }}
                                  />
                                )}
                              </InfiniteLoader>
                            )
                          );
                        }}
                      </AutoSizer>
                    ) : (
                      rowData &&
                      rowData.childList &&
                      rowData.childList.map((childRow, childIndex) => (
                        <Row
                          key={childRow.name}
                          childRow={childRow}
                          index={childIndex + 1}
                          handleRevert={handleRevert}
                          site={site}
                          level={level + 1}
                          item={item}
                          color={"#F5FAFF"}
                          setLoading={setLoading}
                          successAlert={successAlert}
                          errorAlert={errorAlert}
                          getApprovedBal={getApprovedBal}
                          tableData={tableData}
                          setData={setData}
                          totalProfit={totalProfit}
                          navigateToLedger={navigateToLedger}
                          fromDate={fromDate}
                          toDate={toDate}
                          monthId={monthId}
                          showOpeningBalance={showOpeningBalance}
                          isBalanceWiseData={isBalanceWiseData}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function BalanceSheetTable({
  data,
  setData,
  pageNo,
  setPageNo,
  totalPages,
  setPageSize,
  pageSize,
  handleRevert,
  site,
  totalRevenue,
  totalExpenses,
  netAmount,
  setLoading,
  successAlert,
  errorAlert,
  getApprovedBal,
  navigateToLedger,
  fromDate,
  toDate,
  monthId,
  showOpeningBalance,
  isBalanceWiseData,
  vertHoriOpen
}) {
  const onPageChange = (event, value) => setPageNo(value);

  // Filter out the "Income" and "Expenses" sections
  const filteredData = Object.keys(data)
    .filter(
      (key) =>
        key === "leftAccountGroupFetchResponseList" ||
        key === "rightAccountGroupFetchResponseList"
    )
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  const reversedFilteredData = Object.keys(filteredData)
    .reverse()
    .reduce((obj, key) => {
      obj[key] = filteredData[key];
      return obj;
    }, {});

  const getColorBasedOnSubtraction = (
    dataArray,
    balanceKey,
    openingBalanceKey
  ) => {
    return (
      dataArray &&
      dataArray.map((accountGroup) => {
        const balanceDifference =
          accountGroup[balanceKey] - accountGroup[openingBalanceKey];

        if (balanceDifference === 0) {
          return "black";
        } else if (balanceDifference > 0) {
          return "red";
        } else {
          return "green";
        }
      })
    );
  };
  return (
    <>
      <TableContainer
        style={{ overflow: "auto", height: vertHoriOpen ? "60vh" : "auto" }}
      >
        <Grid container spacing={2}>
          {reversedFilteredData &&
          Object.keys(reversedFilteredData).length > 0 ? (
            Object.keys(reversedFilteredData).map((item) => {
              return (
                <>
                  <Grid
                    item
                    md={vertHoriOpen ? 12 : 6}
                    lg={vertHoriOpen ? 12 : 6}
                    key={item} // Ensure to add a unique key here
                    style={{
                      maxHeight: "calc(-290px + 100vh)",
                      overflow: "auto"
                    }}
                  >
                    <Table aria-label="collapsible table" stickyHeader>
                      <TableHead>
                        <StyledTableRow
                          sx={{ position: "sticky", zIndex: 10000 }}
                        >
                          <StyledTableCell
                            colSpan={12}
                            sx={{ borderRadius: "6px", textAlign: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0 20px"
                              }}
                            >
                              <p style={{ textTransform: "capitalize" }}>
                                {reversedFilteredData[item]?.length > 0 &&
                                  reversedFilteredData[item][0]?.name}
                              </p>
                              <p>
                                Amount (
                                {!getApprovedBal ? (
                                  <AmountInfo
                                    amount={
                                      reversedFilteredData.leftAccountGroupFetchResponseList.reduce(
                                        (total, child) =>
                                          total +
                                          (child?.accountGroupCurrentBalance -
                                            child?.openingBalance),
                                        0
                                      ) + (netAmount < 0 ? netAmount : 0)
                                    }
                                  />
                                ) : (
                                  <AmountInfo
                                    amount={
                                      (reversedFilteredData[item][0]
                                        ?.approvedOpeningBalance || 0) -
                                      (reversedFilteredData[item][0]
                                        ?.approvedAccountGroupCurrentBalance ||
                                        0)
                                    }
                                  />
                                )}
                                )
                              </p>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <div style={{ height: "9px" }} />
                      {reversedFilteredData[item].length > 0 ? (
                        <TableBody sx={{ overflow: "scroll" }}>
                          {reversedFilteredData[item].map((row, index) => (
                            <Row
                              key={row.name} // Ensure row name is unique
                              childRow={row}
                              index={(pageNo - 1) * pageSize + index + 1}
                              handleRevert={handleRevert}
                              site={site}
                              level={0}
                              item={item}
                              color={"#DDEEFF"}
                              setLoading={setLoading}
                              successAlert={successAlert}
                              errorAlert={errorAlert}
                              getApprovedBal={getApprovedBal}
                              tableData={data}
                              setData={setData}
                              totalProfit={
                                getApprovedBal
                                  ? data?.netApprovedBalance
                                  : data?.netBalance
                              }
                              navigateToLedger={navigateToLedger}
                              fromDate={fromDate}
                              toDate={toDate}
                              monthId={monthId}
                              showOpeningBalance={showOpeningBalance}
                              isBalanceWiseData={isBalanceWiseData}
                            />
                          ))}
                          {netAmount < 0 &&
                          item === "leftAccountGroupFetchResponseList" ? (
                            <TableRow
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#DDEEFF",
                                marginTop: "1rem",
                                border: "2px solid red"
                              }}
                            >
                              <TableCell
                                style={{ padding: "12px" }}
                                colSpan={vertHoriOpen ? 12 : 6}
                              >
                                <Typography
                                  variant="small1"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "red",
                                      paddingLeft: "30px"
                                    }}
                                  >
                                    Loss
                                  </span>
                                  <span>
                                    <AmountInfo amount={netAmount} />
                                  </span>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ) : netAmount >= 0 &&
                            item === "rightAccountGroupFetchResponseList" ? (
                            <TableRow
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#DDEEFF",
                                marginTop: "1rem",
                                border: "2px solid red"
                              }}
                            >
                              <TableCell
                                colSpan={6}
                                style={{
                                  padding: "12px",
                                  backgroundColor: "#DDEEFF"
                                }}
                              >
                                <Typography
                                  variant="small1"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "green",
                                      paddingLeft: "30px"
                                    }}
                                  >
                                    Profit
                                  </span>
                                  <span>
                                    <AmountInfo amount={netAmount} />
                                  </span>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell
                              className="tableData"
                              colSpan={vertHoriOpen ? 12 : 6}
                              sx={{ textAlign: "center" }}
                            >
                              No Data to Show
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      )}
                    </Table>
                  </Grid>
                  {/* Total Revenue grid print after Revenue section */}
                  {vertHoriOpen &&
                    reversedFilteredData[item][0]?.name === "Assets" && (
                      <Grid item md={12} lg={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderRadius: "6px",
                            background: "#f3a0a0",
                            height: "38px",
                            alignItems: "center",
                            padding: "0px 15px",
                            fontWeight: "600",
                            marginTop: "1rem"
                          }}
                        >
                          <p>{vertHoriOpen ? "Total Assets" : "Total"}</p>
                          <p
                            style={{
                              color: getColorBasedOnSubtraction(
                                reversedFilteredData?.leftAccountGroupFetchResponseList,
                                getApprovedBal
                                  ? "approvedAccountGroupCurrentBalance"
                                  : "accountGroupCurrentBalance",
                                getApprovedBal
                                  ? "approvedOpeningBalance"
                                  : "openingBalance"
                              )
                            }}
                          >
                            {reversedFilteredData?.leftAccountGroupFetchResponseList && (
                              <AmountInfo
                                amount={
                                  reversedFilteredData.leftAccountGroupFetchResponseList.reduce(
                                    (total, child) =>
                                      total +
                                      (getApprovedBal
                                        ? child?.approvedAccountGroupCurrentBalance -
                                          child?.approvedOpeningBalance
                                        : child?.accountGroupCurrentBalance -
                                          child?.openingBalance),
                                    0
                                  ) + (netAmount < 0 ? netAmount : 0)
                                }
                              />
                            )}
                          </p>
                        </div>
                      </Grid>
                    )}
                </>
              );
            })
          ) : (
            <>
              {["Revenue", "Expenses"].map((label) => (
                <Grid
                  item
                  md={vertHoriOpen ? 12 : 6}
                  lg={vertHoriOpen ? 12 : 6}
                  key={label}
                >
                  <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell colSpan={12}>{label}</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={12}
                          sx={{ textAlign: "center" }}
                        >
                          No Data to Show
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </TableContainer>

      <Grid container spacing={2}>
        {!vertHoriOpen && (
          <Grid item md={vertHoriOpen ? 12 : 6} lg={vertHoriOpen ? 12 : 6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "6px",
                background: "#f3a0a0",
                height: "38px",
                alignItems: "center",
                padding: "0px 15px",
                fontWeight: "600",
                marginTop: "1rem"
              }}
            >
              <p>{vertHoriOpen ? "Total Assets" : "Total"}</p>
              <p
                style={{
                  color: getColorBasedOnSubtraction(
                    reversedFilteredData?.leftAccountGroupFetchResponseList,
                    getApprovedBal
                      ? "approvedAccountGroupCurrentBalance"
                      : "accountGroupCurrentBalance",
                    getApprovedBal ? "approvedOpeningBalance" : "openingBalance"
                  )
                }}
              >
                {reversedFilteredData?.leftAccountGroupFetchResponseList && (
                  <AmountInfo
                    amount={
                      reversedFilteredData.leftAccountGroupFetchResponseList.reduce(
                        (total, child) =>
                          total +
                          (getApprovedBal
                            ? child?.approvedAccountGroupCurrentBalance -
                              child?.approvedOpeningBalance
                            : child?.accountGroupCurrentBalance -
                              child?.openingBalance),
                        0
                      ) + (netAmount < 0 ? netAmount : 0)
                    }
                  />
                )}
              </p>
            </div>
          </Grid>
        )}

        <Grid item md={vertHoriOpen ? 12 : 6} lg={vertHoriOpen ? 12 : 6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "6px",
              textAlign: "center",
              fontWeight: 600,
              backgroundColor: "#a7f3a7",
              height: "38px",
              alignItems: "center",
              padding: "0px 15px",
              fontWeight: "600",
              marginTop: "1rem"
            }}
          >
            <p>{vertHoriOpen ? "Total Liabilities" : "Total"}</p>
            <p
              style={{
                color: getColorBasedOnSubtraction(
                  reversedFilteredData?.rightAccountGroupFetchResponseList,
                  getApprovedBal
                    ? "approvedAccountGroupCurrentBalance"
                    : "accountGroupCurrentBalance",
                  getApprovedBal ? "approvedOpeningBalance" : "openingBalance"
                )
              }}
            >
              {reversedFilteredData?.rightAccountGroupFetchResponseList && (
                <AmountInfo
                  amount={
                    reversedFilteredData.rightAccountGroupFetchResponseList.reduce(
                      (total, child) =>
                        total +
                        (getApprovedBal
                          ? child?.approvedAccountGroupCurrentBalance -
                            child?.approvedOpeningBalance
                          : child?.accountGroupCurrentBalance -
                            child?.openingBalance),
                      0
                    ) + (netAmount > 0 ? netAmount : 0)
                  }
                />
              )}
            </p>
          </div>
        </Grid>
      </Grid>

      <ColorInfo />
      <Divider />
    </>
  );
}
