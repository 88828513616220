import React from "react";
import {
  TextField,
  Grid,
  Autocomplete,
  InputAdornment,
  IconButton
} from "@mui/material";
import { FcCalendar } from "react-icons/fc";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccessibleIcon from "@mui/icons-material/Accessible";
import { Height } from "@mui/icons-material";

const FilterTemplate = ({ filteredValues }) => {
  return (
    <>
      {filteredValues &&
        Array.isArray(filteredValues) &&
        filteredValues.length > 0 &&
        filteredValues.map((val, index) => (
          <Grid item key={index}>
            {val.typeId === 1 ? (
              <TextField
                label={val.label}
                style={val.style}
                variant="outlined"
                size="small"
                margin="dense"
                value={val?.value || ""}
                onChange={(e) => val.function(e)}
              />
            ) : val.typeId === 2 ? (
              <Autocomplete
                options={val?.options || []}
                size="small"
                margin="dense"
                multiple={val?.multiple}
                limitTags={val?.multiple && val.limitTags}
                style={val.style}
                value={val?.value || ""}
                getOptionLabel={(option) =>
                  option ? (val?.type ? option[val?.type]?.toString() : option?.toString()) : ""
                }
                getOptionSelected={(option, value) =>
                  val?.type
                    ? option[val?.type] === value[val?.type]
                    : option === value
                }
                onChange={(event, newValue) => {
                  val.function(event, newValue);
                }}
                variant="outlined"
                disabled={val?.disable}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={val?.label}
                    style={{
                      height: "auto"
                    }}
                    variant="outlined"
                    onChange={val.handleInputChangeFilters}
                  />
                )}
              />
            ) : val.typeId === 3 ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={val?.label}
                  value={val?.value}
                  margin="dense"
                  name="dob"
                  disabled={val?.isDisabled}
                  format="dd/MM/yyyy"
                  views={["year", "month", "date"]}
                  components={{ OpenPickerIcon: AccessibleIcon }}
                  style={val.style}
                  inputVariant="outlined"
                  onChange={(value) => val.function(value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility">
                          <FcCalendar />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </MuiPickersUtilsProvider>
            ) : val.typeId === 4 ? (
              <Autocomplete
                options={val?.options || []}
                size="small"
                margin="dense"
                multiple={val?.multiple}
                limitTags={val?.multiple && val.limitTags}
                style={val.style}
                value={val?.value || ""}
                getOptionLabel={(option) =>
                  option ? (val?.type ? option[val?.type]?.toString() : option?.toString()) : ""
                }
                getOptionSelected={(option, value) =>
                  val?.type
                    ? option[val?.type] === value[val?.type]
                    : option === value
                }
                onChange={(event, newValue) => {
                  val.function(event, newValue);
                }}
                variant="outlined"
                disabled={val?.disable}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={val?.label}
                    variant="outlined"
                    onChange={val.debounceHandle}
                  />
                )}
              />
            ) : (
              <></>
            )}
          </Grid>
        ))}
    </>
  );
};

export default FilterTemplate;
