import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  IconButton,
  Paper,
  Container,
  Grid,
  Stack,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Switch,
  Button,
  Badge,
  Typography,
  Box
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { FiPlus } from "react-icons/fi";
import { userService } from "../userService";
import { AlertBar } from "../Alert/AlertBar";
import moment from "moment";
import SideBar from "../CommonComps/SideBar";
import Header from "../CommonComps/Header";
import ErrorModal from "../AccountType/ErrorModal";
import LoadingComponent from "../Loader";
import IconTemplate from "../CommonComps/IconTemplate";
import { styles } from "../Theme/Theme";
import { AiFillFileExcel, AiFillFilePdf } from "react-icons/ai";
import TuneIcon from "@mui/icons-material/Tune";
import AutoDialogBox from "../CommonComps/AutoDialogBox";
import PayableImg from "../images/payable.png";
import { userConfig } from "../config/UserConfig";
import { checkPathExist, checkPermissionExist } from "../utils";
import {
  getAllState,
  transactionsUrl,
  transactionVoucher,
  voucherTypesUrl
} from "../config";
import FlexHeaderDivider from "./FlexHeaderDivider";
import TotalPayIcon from "../images/TotalPay.svg";
import PaidIcon from "../images/Paid.svg";
import PendingIcon from "../images/Pending.svg";
import DueIcon from "../images/Due.svg";
import OverDueIcon from "../images/OverDue.svg";
import AdvanceIcon from "../images/Advance.svg";
import { any } from "prop-types";
import { getEncryptPayload } from "../Network_Utility";
import { PayableReportsAction } from "../features/actions/PayableReportsAction";
import PayableTable from "./PayableTable";
import VendorBalanceSummary from "./VendorBalanceSummary";
import { Groups } from "@mui/icons-material";
import {
  AgingSummaryStaticData,
  DetailsFilterStatusHeader,
  PayableStatusHeader
} from "./Utils";

const dueDaysArray = [
  { id: 1, name: "15 Day", amount: "₹19,08,431" },
  { id: 2, name: "30 Day", amount: "₹18,07,430" },
  { id: 3, name: "60 Day", amount: "₹17,06,429" },
  { id: 4, name: "90 Day", amount: "₹16,05,428" }
];

const Index = ({ classes, path }) => {
  const recentPath = window?.location?.pathname;
  const currentTable =
    window.location.pathname === "/aging-Summary-Details"
      ? "accountAgingSummaryTable"
      : window.location.pathname === "/vendor-Balance-Summary"
      ? "vendorBalanceSummaryTable"
      : window.location.pathname === "/unpaid-Bills-Details"
      ? "unPaidBillSummaryTable"
      : "vendorBalanceSummaryTable";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let reduxState = useSelector((val) => val?.rootReducer);
  const siteList = reduxState?.sitesReducer?.data;
  let endPoints = reduxState?.permissionReducer?.data;
  let storedSite = reduxState?.siteReducer?.site;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [selectedSite, setSelectedSite] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [payableHeaderData, setPayableHeaderData] = useState(null);
  const [selectedDays, setSelectedDays] = React.useState({
    Due: dueDaysArray && dueDaysArray[0],
    OverDue: dueDaysArray && dueDaysArray[0]
  });
  const [group, setGroup] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedAmountStatus, setSelectedAmountStatus] = useState({
    id: 1,
    name: "All"
  });

  const [vouchers, setVouchers] = useState([]);
  const [nextActionList, setNextActionList] = useState([]);
  const [showAllTransaction, setShowAllTransaction] = useState(false);
  const [headerConfig, setHeaderConfig] = useState([]);
  const [transactionLockingList, setTransactionLockingList] = useState([]);
  const [mainTransactionData, setMainTransactionData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [site, setSite] = useState(null);

  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: ""
  });

  // Function to show success alert
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched succesfully",
      alertType: "success"
    });
    setLoading(false);
  };

  // Function to show error
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error"
    });
    setLoading(false);
  };

  const MySwitch = ({ checked, onChange, label }) => (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} />}
      label={label}
    />
  );

  const handleClickOpen = (event) => {
    setOpen(true);
  };

  const handleDelete = (ele) => {
    userConfig.hideColumn(window.location.pathname, currentTable, ele.id);
    const getHeaderConfig = userConfig.getHeader(
      window.location.pathname,
      currentTable
    );
    setHeaderConfig(getHeaderConfig);
  };

  const columnHandle = (val) => {
    if (val !== null) {
      if (val?.alias === "all") {
        userConfig.showAllColumn(window.location.pathname, currentTable);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
        return;
      }
      if (val?.alias === "remove") {
        userConfig.hideAllColumn(window.location.pathname, currentTable);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
        return;
      }
      if (val?.id) {
        userConfig.showColumn(window.location.pathname, currentTable, val?.id);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
      }
    }
  };

  const renderAutocomplete = (label, value, width) => (
    <Grid item>
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="small"
        margin="dense"
        style={{ minWidth: width, maxWidth: 300 }}
        onChange={() => handleClickOpen(label)}
        value={
          value && value.length > 0
            ? value[0]?.name +
              (value.length > 1 ? " +" + (value.length - 1) : "")
            : ""
        }
        onClick={() => handleClickOpen(label)}
      />
    </Grid>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <SideBar
      anchor={anchor}
      toDate={toDate}
      handleToDate={handleToDate}
      fromDate={fromDate}
      handleFromDate={handleFromDate}
      toggleDrawer={toggleDrawer}
      filteredValues={filteredValues}
      handleSubmitFilterValue={handleSubmitFilterValue}
      handleResetFilter={handleResetFilter}
    />
  );

  const filteredValues = [
    {
      typeId: 3,
      label: "From Date",
      value: fromDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleFromDate(value, "date")
    },
    {
      typeId: 3,
      label: "To Date",
      value: toDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleToDate(value, "date")
    },
    {
      typeId: 2,
      label: "Groups",
      options: [],
      value: group || "",
      style: { width: 200, margin: "0 15px" },
      function: (event, newValue) => {
        setGroup(newValue);
      },
      className: null,
      type: "name"
    },
    {
      typeId: 2,
      label: "Status",
      options: PayableStatusHeader || [],
      value: status || "",
      style: { width: 200, margin: "0 15px" },
      function: (event, newValue) => {
        setStatus(newValue);
      },
      className: null,
      type: "name"
    }
  ];

  // Function to handle  to date
  const handleToDate = (value, type) => {
    if (type === "date") {
      const fromDateCurrent = moment(fromDate);
      const toDateCurrent = moment(value);
      const result = toDateCurrent.diff(fromDateCurrent, "days");
      const alertMessage =
        result < 0 ? "To Date should be after From Date" : "";

      setAlerts({
        visible: result < 0,
        alertMessage,
        alertType: "error"
      });

      if (result >= 0) {
        setToDate(value);
      }
    }
  };

  // Function to handle from date
  const handleFromDate = (value, type) => {
    if (type === "date") {
      if (toDate != null) {
        const toDateCurrent = moment(toDate);
        const fromDateCurrent = moment(value);
        const result = toDateCurrent.diff(fromDateCurrent, "days");
        const alertMessage =
          result < 0 ? "From Date should be before To Date" : "";

        setAlerts({
          visible: result < 0,
          alertMessage,
          alertType: "error"
        });

        if (result >= 0) {
          setFromDate(value);
        }
      } else {
        setFromDate(value);
      }
    }
  };

  console.log(selectedDays, "bxzcdfhj");

  const payableHeader = [
    {
      id: 1,
      name: "Total Amount",
      transaction: 12,
      client: 5,
      amount: "₹3,908,431",
      icon: TotalPayIcon,
      color: "rgba(52, 122, 226, 1)"
    },
    {
      id: 2,
      name: "Paid",
      transaction: 12,
      client: 5,
      amount: "₹4,89,100",
      icon: PaidIcon,
      color: "rgba(28, 195, 35, 1)"
    },
    {
      id: 2,
      name: "Pending",
      transaction: 12,
      client: 5,
      amount: "₹14,19,331",
      icon: PendingIcon,
      color: "rgba(255, 138, 0, 1)"
    },
    {
      id: 3,
      name: "Due",
      transaction: 9,
      client: 5,
      amount: selectedDays ? selectedDays?.Due?.amount : "₹19,08,431",
      icon: DueIcon,
      color: "rgba(255, 0, 0, 1)"
    },
    {
      id: 4,
      name: "Over Due",
      transaction: 9,
      client: 5,
      amount: selectedDays?.["Over Due"]?.amount ?? "₹19,08,431",
      icon: OverDueIcon,
      color: "rgba(255, 199, 0, 1)"
    },
    {
      id: 5,
      name: "Advance",
      transaction: 12,
      client: 5,
      amount: "₹0",
      icon: AdvanceIcon,
      color: "rgba(33, 66, 243, 1)"
    }
  ];

  const handleSubmitFilterValue = () => {
    setState({ ...state, right: false });
    const permissionExist = checkPermissionExist(endPoints, transactionsUrl);
    if (permissionExist)
      successAlert("Authorized to access transactioTotalPayIconns report.");
    else errorAlert("unauthorized to access transactions report.");
    return true;
  };

  const handleResetFilter = () => {
    const filterValuesData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));

    const currentLocation = window.location.pathname;
    if (filterValuesData) {
      const updatedFilterData = filterValuesData?.filter(
        (ele) => ele?.pathname !== currentLocation
      );
      localStorage.setItem("filter", JSON.stringify(updatedFilterData));
      setFromDate(null);
      setToDate(null);
      setState({ ...state, right: false });
    }
  };

  useEffect(() => {
    const filteredData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));

    const currentLocation = window.location.pathname;
    if (filteredData) {
      const findCurrentFilter = filteredData.find(
        (ele) => ele.pathname === currentLocation
      );

      if (findCurrentFilter) {
        const filterValues = findCurrentFilter.value;
        setFromDate(
          filterValues.find((item) => item.label === "From Date")?.value || null
        );
        setToDate(
          filterValues.find((item) => item.label === "To Date")?.value || null
        );
      }
    }
  }, []);

  const isFilterApplied = fromDate || toDate || group || status;

  useEffect(() => {
    const currentPage = window.location.pathname;
    const getHeaderConfig = userConfig.getHeader(currentPage, currentTable);
    setHeaderConfig(getHeaderConfig);
  }, [currentTable]);

  const getPayableHeaderList = async () => {
    const isPath = checkPathExist(endPoints, window.location.pathname);
    if (!isPath) return;

    const payload = {
      reportId: 5,
      fromDate: fromDate,
      toDate: toDate,
      siteId: selectedSite ? selectedSite.id : null
    };

    try {
      setLoading(true);
      const encryptedPayload = getEncryptPayload(payload, true);
      const response = await PayableReportsAction.getPayableHeader(
        encryptedPayload
      );

      if (response && response.data && response.status === 200) {
        setPayableHeaderData(response.data[0]);
        successAlert("Success");
      } else {
        setPayableHeaderData(response?.data[0]);
        successAlert("No Data available right now!");
      }
    } catch (error) {
      errorAlert("Something went wrong!");
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  useEffect(() => {
    getPayableHeaderList();
    getTransactionList();
  }, [selectedSite]);

  // Daybook Transaction API for showing the transaction in payable amount
  const getTransactionList = async (con) => {
    const filteredData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    const findCurrentFilter = filteredData?.find(
      (ele) => ele.pathname === currentLocation
    );
    const filterValues = findCurrentFilter?.value;

    const fromDate =
      filterValues?.find((item) => item.label === "From Date")?.value || null;
    const toDate =
      filterValues?.find((item) => item.label === "To Date")?.value || null;

    let payload = {
      fromDate: fromDate,
      toDate: toDate,
      // pageNo: pageNo,
      // pageSize: pageSize,
      getAllTrans: true,
      siteId: selectedSite?.id || localStorage.getItem("siteId")
    };
    const encryptedPayload = getEncryptPayload(payload, true);
    try {
      setLoading(true);

      const res = await userService.postData(transactionsUrl, encryptedPayload);

      if (res && res.data && res.data.data && res.data.status === 200) {
        const { data, totalLength } = res.data.data;
        setLoading(false);
        if (data && Array.isArray(data) && data.length > 0) {
          setTransactionLockingList(data);
          // setTotalPages(Math.ceil(totalLength / pageSize));
          successAlert(res?.data?.message);
        } else {
          setTransactionLockingList([]);
          // setTotalPages(1);
          successAlert("No records available");
        }
      } else {
        errorAlert(res?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      errorAlert("Unable to fetch data at this moment");
      setLoading(false);
    }
  };

  const getPermissionCombinationList = async (url) => {
    const payload = {
      permissionId:
        endPoints && endPoints.find((val) => val.apiEndpoint === `/${url}`)?.id,
      permissionStateId: null,
      isActive: true,
      roleId: 20
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      const res = await userService.getPermissionCombination(
        getAllState,
        encryptedPayload
      );
      if (res && res.data && res.data.status) {
        let data = res?.data?.data.map((val) => ({
          ...val,
          stateId: val?.stateId?.id,
          name: val?.stateId?.name
        }));
        setNextActionList(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPermissionCombinationList(transactionsUrl);
  }, []);

  function calculateTotalAmount(data) {
    let totalAmountTrue = 0;
    let totalAmountFalse = 0;

    for (const item of data) {
      if (item.isTemporary) {
        totalAmountTrue += item.amount;
      } else {
        totalAmountFalse += item.amount;
      }
    }

    return {
      totalAmountTrue,
      totalAmountFalse
    };
  }

  function setTotalAmount(data) {
    for (const entry of data) {
      const { totalAmountTrue, totalAmountFalse } = calculateTotalAmount(
        entry.items
      );

      // Check if all items have the same isTemporary value
      const isAllTemporaryTrue = entry.items.every((item) => item.isTemporary);
      const isAllTemporaryFalse = entry.items.every(
        (item) => !item.isTemporary
      );

      // Calculate the total amount based on the conditions
      let totalAmount;
      if (isAllTemporaryTrue || isAllTemporaryFalse) {
        totalAmount = totalAmountTrue + totalAmountFalse;
      } else {
        totalAmount = isAllTemporaryTrue ? totalAmountTrue : totalAmountFalse;
      }

      // Set the totalAmount property for the array
      entry.totalAmount = totalAmount;

      for (const item of entry.items) {
        if (item.isTemporary) {
          item.totalAmount = totalAmountTrue;
        } else {
          item.totalAmount = totalAmountFalse;
        }
      }
    }

    return data;
  }

  const getAmount = (transactionLocking) => {
    const groupData = transactionLocking.reduce((acc, item) => {
      const {
        txnId,
        amount,
        isTemporary,
        isIgstTransaction,
        isCgstTransaction,
        isSgstTransaction
      } = item;
      const gstAmount =
        isSgstTransaction && isIgstTransaction && isCgstTransaction
          ? amount
          : 0;

      if (!acc[txnId]) {
        acc[txnId] = {
          totalAmount: 0,
          items: [],
          igstAmount: 0,
          cgstAmount: 0,
          sgstAmount: 0,
          gstAmount: 0
        };
      }

      if (!isTemporary) {
        acc[txnId].totalAmount += amount;
      }

      acc[txnId].items.push(item);
      acc[txnId].igstAmount += isIgstTransaction ? amount : 0;
      acc[txnId].cgstAmount += isCgstTransaction ? amount : 0;
      acc[txnId].sgstAmount += isSgstTransaction ? amount : 0;
      acc[txnId].gstAmount += gstAmount;

      return acc;
    }, {});

    const groupedArray = Object.values(groupData).reverse();
    const result = setTotalAmount(groupedArray); // Save the result

    setMainTransactionData(result);
  };

  useEffect(() => {
    getAmount(transactionLockingList);
  }, [transactionLockingList]);

  const getVoucherList = async (site) => {
    const permissionExist = checkPermissionExist(endPoints, voucherTypesUrl);
    const isPath = checkPathExist(endPoints, window.location.pathname);
    if (permissionExist && isPath) {
      try {
        const payload = {
          companyId: localStorage.getItem("companyId"),
          siteId: site ? site?.id : localStorage.getItem("siteId")
        };
        const res = await userService.postData(transactionVoucher, payload);
        // const res = await userService.postData(voucherTypesUrl, payload);

        if (res && res.data && res.data.status === 200) {
          if (res.data.data && res.data.data.length > 0) {
            setVouchers(res.data.data);
          } else {
            setVouchers([]);
          }
        } else
          errorAlert(
            res?.data?.message || "Unable to fetch list at this moment"
          );
      } catch (error) {
        errorAlert("Something went wrong");
      }
    }
  };

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Header
              gridSize="3"
              source={PayableImg}
              headerText={
                recentPath === "/aging-Summary-Details"
                  ? "Aging Summary"
                  : recentPath === "/vendor-Balance-Summary"
                  ? "Vendor Balance Summary"
                  : recentPath === "/unpaid-Bills-Details"
                  ? "Unpaid Bills Details Summary"
                  : "Account Payable Summary"
              }
            />
            <Grid
              item
              xs={9}
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              {/* <MySwitch
                checked={showAllTransaction}
                onChange={(e) => {
                  setLoading(true);
                  setShowAllTransaction(e.target.checked);
                  setTimeout(() => {
                    setLoading(false);
                  }, 100);
                }}
                label={
                  !showAllTransaction
                    ? "Expand Transactions"
                    : "Collapse Transactions"
                }
              /> */}
              {renderAutocomplete(
                "Columns",
                (headerConfig || [])?.filter(
                  (ele) =>
                    ele.name !== "S.NO" &&
                    ele.name !== " " &&
                    ele.isShow !== false
                ) || [],
                "250px"
              )}
              {
                <AutoDialogBox
                  columnHandle={columnHandle}
                  handleDelete={handleDelete}
                  open={open}
                  setOpen={setOpen}
                  setScroll={setScroll}
                  // scroll={scroll}
                  scroll={"paper"}
                  columns={(headerConfig || [])?.filter(
                    (ele) => ele.name !== "S.NO" && ele.name !== " "
                  )}
                  heading={"Columns"}
                  autoCompName={"Columns"}
                />
              }
              <Grid item>
                <Autocomplete
                  options={siteList || []}
                  size="small"
                  margin="dense"
                  style={{
                    width: 200,
                    display: checkPermissionExist(endPoints, transactionsUrl)
                      ? "inline-flex"
                      : "none"
                  }}
                  value={selectedSite || ""}
                  getOptionLabel={(option) =>
                    option ? option.name || option.name : ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                    // getAccountsList(newValue);
                    // getAccountGroup(newValue);
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} label="Site" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item>
                <div
                  style={{
                    display: checkPermissionExist(endPoints, transactionsUrl)
                      ? "inline-flex"
                      : "none"
                  }}
                >
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      {isFilterApplied ? (
                        <Badge color="primary" variant="dot">
                          <IconButton
                            style={{ background: "#c35151", color: "#fff" }}
                            onClick={toggleDrawer(anchor, true)}
                          >
                            <TuneIcon />
                          </IconButton>
                        </Badge>
                      ) : (
                        <IconButton
                          style={{ background: "#c35151", color: "#fff" }}
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <TuneIcon />
                        </IconButton>
                      )}
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <FlexHeaderDivider
            payableHeader={payableHeader || []}
            selectedDays={selectedDays}
            dueDaysArray={dueDaysArray}
            setSelectedDays={setSelectedDays}
          />
          <Grid container sx={{ marginTop: "30px" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 550 }}>
                {"Details"}
              </Typography>
              {/* {recentPath === "/aging-Summary-Details" && (
                <Box>
                  {" "}
                  <Autocomplete
                    disablePortal
                    options={DetailsFilterStatusHeader || []}
                    getOptionLabel={(option) => option.name}
                    value={selectedAmountStatus}
                    onChange={(_, newValue) =>
                      setSelectedAmountStatus(newValue)
                    }
                    size="small"
                    sx={{
                      width: 150, // full width on small screens
                      background: "#fff",
                      borderRadius: "8px"
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          ".MuiOutlinedInput-root": {
                            borderRadius: "4px"
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid rgba(195, 212, 255, 1)"
                          },
                          ".MuiInputBase-input": {
                            height: "16px"
                          }
                        }}
                      />
                    )}
                  />
                </Box>
              )} */}
            </Grid>
            <Grid item xs={12}>
              {/* {recentPath !== "/vendor-Balance-Summary" ? ( */}
              <PayableTable
                headerConfig={headerConfig}
                data={AgingSummaryStaticData || []}
                selectedColumns={selectedColumns}
                showAllTransaction={showAllTransaction}
                site={site}
                voucherList={vouchers}
                nextActionList={nextActionList}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
              {/* ) : (
                <VendorBalanceSummary
                  headerConfig={headerConfig}
                  data={transactionLockingList}
                  mainTransactionData={mainTransactionData}
                  selectedColumns={selectedColumns}
                  showAllTransaction={showAllTransaction}
                  site={site}
                  voucherList={vouchers}
                  nextActionList={nextActionList}
                />
              )} */}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default withStyles(styles)(Index);
