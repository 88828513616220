import config from "../../config";
import { userService } from "../../userService";

export const PayableReportsAction = {
  getPayableHeader
};

async function getPayableHeader(payload) {
  try {
    const apiEndPoint = `${config.baseUrl}${config.getpayableHeaderUrl}`;
    const response = await userService.postData(apiEndPoint, payload);
    // alert(response)
    if (response) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}
