import { siteUrl, headOfficeSiteUrl } from "../../config";
import {
  accountAgingSummary,
  dayBookTableHeaders,
  generalLedgerTableHeader,
  gstr1Report,
  gstr2Report,
  gstr3BReport,
  OverdueAnalysis,
  ReceivableAgileSummary,
  ReceivableCustomerBalanceSummary,
  ReceivableUnpaidBillSummary,
  trialBalanceSiteWiseTableHeader,
  unPaidBillSummary,
  vendorBalanceSummary
} from "../../config/ColumnJson";
import { userService } from "../../userService";
import {
  fetchFinancialYearMonth,
  fetchFinancialYears
} from "./FinancialYearsAction";
import { setNewSite } from "./siteAction";

const DATA_FETCH_SUCCESS = "DATA_FETCH_SUCCESS";
const DATA_FETCH_FAILURE = "DATA_FETCH_FAILURE";

const PAGE_URL = [
  "/daybook",
  "/trialBalance",
  "/fixedAssets",
  "/bankBook",
  "/liabilities",
  "/profitLoss",
  "/balanceSheet",
  "/cashflow",
  "/generalLedger",
  "/aging-Summary-Details",
  "/vendor-Balance-Summary",
  "/unpaid-Bills-Details",
  "/accounts-Payable-Summary",
  "/receivable/aging-summary",
  "/receivable/customer-balance-summary",
  "/receivable/invoice-list-summary",
  "/receivable/account-receivable-summary",
  "/gst/gst-1",
  "/gst/gst-2",
  "/gst/gst-3B",
  "/over-due-analysis"
];

const getHeadOffice = (
  data,
  setIsLoading,
  dispatch,
  errorAlert,
  primarySiteId,
  navigate,
  permissionList
) => {
  userService
    .get(headOfficeSiteUrl)
    .then((res) => {
      if (res && res.data && res.data) {
        let sites = data;
        sites.forEach((site) => {
          site.isHeadOffice = site.id === res.data.data;
        });
        const filteredSIte =
          sites.find(
            (val) => val?.name === "HEAD OFFICE" || val?.name === "Head Office"
          ) || sites[0];
        localStorage.setItem("siteId", primarySiteId || filteredSIte.id);
        dispatch(siteRequestSucess(sites));
        dispatch(setNewSite(primarySiteId || filteredSIte));

        setTimeout(() => {
          dispatch(
            fetchFinancialYears(
              setIsLoading,
              errorAlert,
              navigate,
              permissionList
            )
          );
          dispatch(fetchFinancialYearMonth(setIsLoading, errorAlert));
        }, 100);
      } else {
        return false;
      }
    })
    .catch((err) => {
      errorAlert("Something went wrong");
    });
};

export function fetchSiteList(
  setIsLoading,
  errorAlert,
  primarySiteId,
  navigate,
  permissionList
) {
  return (dispatch) => {
    setIsLoading(true);

    userService
      .getSites(siteUrl)
      .then((res) => {
        if (res && res.data && res.data.data) {
          let sites = res.data.data;
          const sitesArray = sites?.map((ele) => ({ ...ele, isShow: true }));
          const finalSiteName = [
            { id: 909, name: "Select All", alias: "all", isShow: false },
            { id: 910, name: "Remove All", alias: "remove", isShow: false },
            ...sitesArray
          ];

          const columnConfig = [
            {
              id: 1,
              page: PAGE_URL[0],
              dayBookTable: dayBookTableHeaders
            },
            {
              id: 2,
              page: PAGE_URL[1],
              siteWiseTable: trialBalanceSiteWiseTableHeader,
              siteName: finalSiteName
            },
            {
              id: 3,
              page: PAGE_URL[2],
              siteName: finalSiteName
            },
            {
              id: 4,
              page: PAGE_URL[3],
              siteName: finalSiteName
            },
            {
              id: 5,
              page: PAGE_URL[4],
              siteName: finalSiteName
            },
            {
              id: 6,
              page: PAGE_URL[5],
              siteName: finalSiteName
            },
            {
              id: 7,
              page: PAGE_URL[6],
              siteName: finalSiteName
            },
            {
              id: 8,
              page: PAGE_URL[7],
              siteName: finalSiteName
            },
            {
              id: 9,
              page: PAGE_URL[8],
              generalLedger: generalLedgerTableHeader
            },
            {
              id: 10,
              page: PAGE_URL[9],
              accountAgingSummaryTable: accountAgingSummary
            },
            {
              id: 11,
              page: PAGE_URL[10],
              vendorBalanceSummaryTable: vendorBalanceSummary
            },
            {
              id: 12,
              page: PAGE_URL[11],
              unPaidBillSummaryTable: unPaidBillSummary
            },
            {
              id: 13,
              page: PAGE_URL[12],
              vendorBalanceSummaryTable: vendorBalanceSummary
            },
            {
              id: 14,
              page: PAGE_URL[13],
              receivableAgileSummaryHeader: ReceivableAgileSummary
            },
            {
              id: 15,
              page: PAGE_URL[14],
              customerBalanceSummaryHeader: ReceivableCustomerBalanceSummary
            },
            {
              id: 16,
              page: PAGE_URL[15],
              receivableUnpaidBillSummaryHeader: ReceivableUnpaidBillSummary
            },
            {
              id: 17,
              page: PAGE_URL[16],
              customerBalanceSummaryHeader: ReceivableCustomerBalanceSummary
            },
            {
              id: 18,
              page: PAGE_URL[17],
              GSTR1ReportTable: gstr1Report
            },
            {
              id: 19,
              page: PAGE_URL[18],
              GSTR2ReportTable: gstr2Report
            },
            {
              id: 20,
              page: PAGE_URL[19],
              GSTR3ReportTable: gstr3BReport
            },
            {
              id: 21,
              page: PAGE_URL[20],
              OverdueAnalysisHeader: OverdueAnalysis
            }
          ];
          localStorage.setItem("columnConfig", JSON.stringify(columnConfig));

          getHeadOffice(
            sites,
            setIsLoading,
            dispatch,
            errorAlert,
            primarySiteId,
            navigate,
            permissionList
          );
        } else {
          dispatch(siteRequestFailure(res.data));
          errorAlert(res?.data?.message || "Something went wrong");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        errorAlert("Something went wrong");
        setIsLoading(false);
      });
  };
}

export const siteRequestFailure = (data) => {
  return {
    type: DATA_FETCH_FAILURE,
    data: data
  };
};
export const siteRequestSucess = (data) => {
  return {
    type: DATA_FETCH_SUCCESS,
    data: data
  };
};
