import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeCalculator } from "../features/actions/calculatorAction";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";

const SlideUpTransition = React.forwardRef(function SlideUp(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Calculator = () => {
  const reduxState = useSelector((val) => val?.rootReducer);
  const dispatch = useDispatch();
  const open = reduxState.calculator?.isOpen;
  const [expression, setExpression] = useState("");
  const [results, setResults] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);

  const handleClose = () => {
    dispatch(closeCalculator());
  };

  // const calculateResult = (e) => {
  //   e.stopPropagation();
  //   if (e.key === "Enter") {
  //     debugger
  //     e.preventDefault();
  //     try {
  //       const sanitizedExpression = expression.replace(/\s+/g, "");
  //       const evalResult = evaluateExpression(sanitizedExpression);
  //       setResults([
  //         ...results,
  //         { expression, result: +evalResult.toFixed(2) },
  //       ]);
  //       setExpression("");
  //       setTimeout(() => inputRef.current.focus(), 0);
  //     } catch (error) {
  //       setResults([...results, { expression, result: "Error" }]);
  //       setExpression("");
  //       setTimeout(() => inputRef.current.focus(), 0);
  //     }
  //   }
  // };

  const calculateResult = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      e.preventDefault();
      if (!expression) return;
      try {
        let sanitizedExpression = expression.replace(/\s+/g, "");
        if (/^[+\-*/%]/.test(sanitizedExpression) && results.length > 0) {
          const previousResult = results[results.length - 1].result;
          if (sanitizedExpression.startsWith("%")) {
            const percentageValue = parseFloat(sanitizedExpression.slice(1));
            sanitizedExpression = `${previousResult} * (${percentageValue} / 100)`;
          } else {
            sanitizedExpression = `${previousResult} ${sanitizedExpression}`;
          }
        }

        const evalResult = evaluateExpression(sanitizedExpression);
        if (evalResult && !isNaN(evalResult)) {
          setResults((prevResults) => [
            ...prevResults,
            {
              expression: sanitizedExpression,
              result: +evalResult.toFixed(2), // Use toFixed only if evalResult is a number
            },
          ]);
        } else {
          console.error("evalResult is not a number:", evalResult);
        }
        setExpression("");
        setTimeout(() => inputRef.current?.focus(), 0);
      } catch (error) {
        setResults((prevResults) => [
          ...prevResults,
          { expression, result: "Error" },
        ]);
        setExpression("");
        setTimeout(() => inputRef.current?.focus(), 0);
      }
    }
  };

  const evaluateExpression = (expr) => {
    ///////////////////////////////////////////////////////
    // Custom logic to handle percentages
    let modifiedExpr = expr.replace(/(\d+)%/g, (match, number) => {
      return `${number} / 100`;
    });
    return eval(modifiedExpr);
  };

  useEffect(() => {
    if (resultsRef.current) {
      resultsRef.current.scrollTop = resultsRef.current.scrollHeight;
    }
  }, [results]);

  const clearHistory = () => {
    setResults([]);
  };

  const deleteResult = (index) => {
    setResults((prevResults) =>
      prevResults.filter((_, resultIndex) => resultIndex !== index)
    );
  };

  const copyToClipboard = (text, index) => {
    const copyUsingClipboardAPI = async () => {
      try {
        await navigator.clipboard.writeText(text);
        console.log("Copied to clipboard:", text);
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 1000);
      } catch (error) {
        console.error("Failed to copy using Clipboard API:", error);
        copyUsingExecCommand();
      }
    };

    // Function to copy text using the execCommand method for older browsers
    const copyUsingExecCommand = () => {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      textArea.style.position = "fixed";
      textArea.style.opacity = "0";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          setCopiedIndex(index);
          setTimeout(() => setCopiedIndex(null), 1000);
        } else {
          console.error("Unable to copy text using execCommand");
        }
      } catch (err) {
        console.error("Fallback error: Unable to copy", err);
      }

      document.body.removeChild(textArea);
    };

    if (navigator.clipboard && navigator.clipboard.writeText) {
      copyUsingClipboardAPI();
    } else {
      copyUsingExecCommand();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideUpTransition}
        PaperProps={{
          style: {
            backgroundColor: "#333",
            color: "#fff",
            maxWidth: "700px",
            margin: "auto",
            position: "fixed",
            bottom: 0,
            right: 0,
            height: "400px",
            display: "flex",
            flexDirection: "column",
            width: "600px",
          },
        }}
      >
        <DialogActions
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <IconButton onClick={handleClose} style={{ color: "#fff" }}>
            <CloseIcon />
          </IconButton>
        </DialogActions>

        <DialogContent
          ref={resultsRef}
          style={{
            paddingTop: "50px",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          {results.map((res, index) => (
            <>
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0",
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <Typography variant="body1" style={{ color: "#bbb" }}>
                    {res.expression}
                  </Typography>
                  <Typography variant="h6">= {res.result}</Typography>
                </div>
                {copiedIndex === index ? (
                  <IconButton style={{ color: "green", marginRight: "8px" }}>
                    <CheckIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => copyToClipboard(res.result, index)}
                    style={{ color: "#fff", marginRight: "8px" }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => deleteResult(index)}
                  style={{ color: "#fff" }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              {index < results.length - 1 && (
                <Divider style={{ backgroundColor: "#666" }} />
              )}
            </>
          ))}
        </DialogContent>

        <div
          style={{
            padding: "8px",
            borderTop: "1px solid #555",
            backgroundColor: "#222",
          }}
        >
          <TextField
            inputRef={inputRef}
            autoFocus
            margin="dense"
            label="Calculate"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            value={expression}
            onChange={(e) => setExpression(e.target.value)}
            onKeyDown={calculateResult}
            InputProps={{
              style: {
                color: "#fff",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#aaa",
              },
            }}
          />
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={clearHistory}
              variant="contained"
              style={{
                backgroundColor: "#ff4444",
                color: "#fff",
              }}
            >
              Clear All
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Calculator;
